import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Card/Card.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/CardContent/CardContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/CardMedia/CardMedia.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Chip/Chip.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/CircularProgress/CircularProgress.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Grid2/Grid2.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Skeleton/Skeleton.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/SvgIcon/SvgIcon.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/breadcrumbs.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/click-outside.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/client-side-toast.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/debounce-input.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/dropdown.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/error.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/logo.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/observable.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/paginations.component.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/src/shared/components/password-input.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/seach-banner.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/search-input.component.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/features/resources-micro-block/resources-micro-block.feature.tsx");
